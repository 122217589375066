import React from "react";
import PageWrapper from "../Components/PageWrapper";

class Error404 extends React.Component{
    render(){
        return(
            <PageWrapper>
                <div>
                    Error 404 - page not found
                </div>
            </PageWrapper>
        )
    }
}

export default Error404;