import React from "react";

const AdminContext = React.createContext(
    {
        adminUserName: "",
        authToken: "",
        adminLoggedIn: false,

        adminSetUserdata: ()=>{}
    }
)

export default AdminContext;