import React from "react";
import Login from "./Pages/Login";
import AdminContext from "./AdminContext";
import AdminGallery from "./Pages/AdminGallery";

class Admin extends React.Component{
    constructor(props){
        super(props)
        this.adminSetUserdata = this.adminSetUserdata.bind(this);

        this.state = {
            adminUserName: "",
            authToken: "",
            adminLoggedIn: false,

            adminSetUserdata: this.adminSetUserdata
        }
    }

    adminSetUserdata(authToken, username, loggedin){
        this.setState({
            adminUserName: username,
            authToken: authToken,
            adminLoggedIn: loggedin
        })
    }

    render(){
        return(
            <AdminContext.Provider value={this.state}>
            <>
                <h1>Admin Panel</h1>
                {
                this.state.adminLoggedIn ? 
                    <AdminGallery />
                : 
                    <Login />
                }
            </>
            </AdminContext.Provider>
        )
    }
}

export default Admin;