import logo from './images/logo.svg';
import { Routes, Route, NavLink } from "react-router-dom";
import './styles/styles.css';
import MainSlider from './Components/MainSlider';
import MainWidgets from './Components/MainWidgets';
import Footer from './Components/Footer';
import Gallery from './Pages/Gallery';
import About from './Pages/About';
import Contacts from './Pages/Contacts';
import Login from './Pages/Login';
import Prices from './Pages/Prices';
import Profile from './Pages/Profile';
import Registration from './Pages/Registration';
import Stories from './Pages/Stories';
import Reservations from './Pages/Reservations';
import Error404 from './Pages/Error404';

function App() {
  return (
    <>
      <header>
            <div className="header-hamburger-menu-wrapper">
                <div className="header-hamburger-menu"></div>
            </div>
            <div className="header-logo-wrapper">
                <div className="header-logo-container">
                    <NavLink to="/">
                        <img src={logo} alt="Cvetnata | Bungalow" className="header-main-logo" />
                    </NavLink>
                </div>
            </div>

            <div className="header-menu-wrapper">
                <div className="main-menu">
                    <nav>
                        <NavLink to="/gallery/" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"} >GALLERY</NavLink>
                        <NavLink to="/reservations/" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"} >RESERVATIONS</NavLink>
                        <NavLink to="/prices/" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"} >PRICE</NavLink>
                        <NavLink to="/about/" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"} >INFO</NavLink>
                        <NavLink to="/stories/" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"} >STORIES</NavLink>
                        <NavLink to="contacts/" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"} >CONTACTS</NavLink>
                    </nav>
                </div>
            </div>

            <div className="header-user-wrapper">
                <div className="header-user-container">
                    <nav>
                        <NavLink to="/login/" className={({ isActive }) => isActive ? "active" : ""} >LOGIN</NavLink>
                        <NavLink to="/registration/" className={({ isActive }) => isActive ? "active" : ""} >REGISTER</NavLink>
                    </nav>
                </div>
            </div>
        </header>

        <MainSlider />
        <MainWidgets />
        <Footer />
        <Routes>
          <Route path="/" element={<></>} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="reservations" element={<Reservations />} />
          <Route path="prices" element={<Prices />} />
          <Route path="about" element={<About />} />
          <Route path="stories" element={<Stories />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="login" element={<Login />} />
          <Route path="registration" element={<Registration />} />

          <Route path="profile" element={<Profile />} />

          <Route path="*" element={<Error404 />} />
        </Routes>
    </>
  );
}


export default App;
