class adminApi {
    static apiUrl = 'https://gardenia.cvetnata.com/admin-api/';

    static setAuthHeaders(hdrs, token){
        if(token){
            hdrs.append("Authorization", `Bearer ${token}`);
            return hdrs;
        }else{
            return hdrs;
        }
    }

    static async login(username, password){
        const fdata = new FormData()
        fdata.append('username', username);
        fdata.append('password', password);
        const requestOptions = {
            method: 'POST',
            body: fdata
        };
        let result = {};
        await fetch(`${this.apiUrl}login/`, requestOptions)
            .then(response => response.json())
            .then(data => {
                result = data;
            })
            .catch(error => {
                result =  {"success" : 0, "status": 800,"message" : error.toString()};
                console.error('There was an error!', error);
            });;

        return result;
    }

    static async galleryGetAllImages(token){
        let myHeaders = new Headers();
        myHeaders = this.setAuthHeaders(myHeaders, token)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
        }

        console.log(myHeaders)

        let result = {}
        await fetch(`${this.apiUrl}gallery/`, requestOptions)
        .then(response => response.json())
        .then(data => {
            result = data;
        })
        .catch(error => {
            result =  {"success" : 0, "status": 800,"message" : error.toString()};
            console.error('There was an error!', error);
        });

        return result;
    }
}

export default adminApi;