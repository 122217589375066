import React from "react";
import AdminContext from "../AdminContext";
import adminApi from "../api/admin-api";

class Login extends React.Component{

    static contextType = AdminContext;

    constructor(props){
        super(props)

        this.login = this.login.bind(this)
        this.onUnChange = this.onUnChange.bind(this);
        this.onPwChange = this.onPwChange.bind(this)

        this.state = {
            data : "",
            username:"",
            password: ""
        }
    }

    onUnChange(e){
        this.setState({
            username:e.target.value
        })
    }

    onPwChange(e){
        this.setState({
            password:e.target.value
        })
    }

    async login(){
        let data = await adminApi.login(this.state.username, this.state.password);
        
        if(data.success === 1){
            this.context.adminSetUserdata(data.token, this.state.username, true);
        }else{
            this.setState({ data: data.message })
        }

    }

    render(){
        return(
            <>
                <div>
                    <label>Username</label>
                    <input type="text" onChange={this.onUnChange} value={this.state.username} />
                </div>
                <div>
                    <label>Password</label>
                    <input type="password" onChange={this.onPwChange} value={this.state.password} />
                </div>
                <div>
                    <button type="button" onClick={this.login} >Login</button>
                </div>
                <div>{this.state.data}</div>
            </>
        )
    }
}

export default Login