import React from "react";
import PageWrapper from "../Components/PageWrapper";

class Gallery extends React.Component{
    render(){
        return(
            <PageWrapper>
                <h1>Gallery Pagge</h1>
            </PageWrapper>
        )
    }
}

export default Gallery