import React from "react";

class Footer extends React.Component{
    render(){
        return(
            <footer>
            <div class="footer-copyright">
                gardenia.cvetnata.com ©2020
            </div>
        </footer>
        )
    }
}

export default Footer;