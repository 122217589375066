import React from "react";
import PageWrapper from "../Components/PageWrapper";

class Reservations extends React.Component{
    render(){
        return(
            <PageWrapper>
                <h1>Reservations Pagge</h1>
            </PageWrapper>
        )
    }
}

export default Reservations