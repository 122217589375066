import React from "react";
import PageWrapper from "../Components/PageWrapper";

class Stories extends React.Component{
    render(){
        return(
            <PageWrapper>
                <h1>Stories Pagge</h1>
            </PageWrapper>
        )
    }
}

export default Stories