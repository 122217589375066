import React from "react";

class MainWidgets extends React.Component{

    render(){
        return(
            <section id="mainWidgets" className="main-widgets-wrapper">
                <div className="main-widgets-container">
                    <div id="widgetCalendar" className="main-widget widget-calendar">
                        <div className="icon widget-icon widget-icon--calendar"></div>
                        <div className="widget-data">
                            <div className="widget-month">
                                <span className="widget-month-name">JUN</span>
                                <span className="widget-month-free-dates">02-22</span>
                            </div>
                            <div className="widget-month">
                                <span className="widget-month-name">JUL</span>
                                <span className="widget-month-free-dates">06-17</span>
                            </div>
                            <div className="widget-month">
                                <span className="widget-month-name">AUG</span>
                                <span className="widget-month-free-dates">12-22</span>
                            </div>
                        </div>
                    </div>

                    <div className="main-widgets-delimiter"></div>

                    <div id="widgetPrice" className="main-widget widget-price">
                        <div className="icon widget-icon widget-icon--price"></div>
                        <div className="widget-data">
                            <div className="widget-price">
                                <span className="widget-price-number">150</span>
                                <span className="widget-price-text">BGN/night</span>
                            </div>
                        </div>
                    </div>

                    <div className="main-widgets-delimiter"></div>

                    <div id="widgetLocation" className="main-widget widget-location">
                        <div className="icon widget-icon widget-icon--location"></div>
                        <div className="widget-data">
                            <div className="widget-position">
                                <div className="widget-position-n">42°49'20.9"N</div>
                                <div className="widget-position-s">24°56'48.9"E</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default MainWidgets;