import React from "react";

class PageWrapper extends React.Component{
    render(){
        return(
            <>
            <div className="fader"></div>

            <section className="page-wrapper">
                {this.props.children}
            </section>
            </>
        )
    }
}

export default PageWrapper