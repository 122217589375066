import React from "react";
import PageWrapper from "../Components/PageWrapper";

class Login extends React.Component{
    render(){
        return(
            <PageWrapper>
                <h1>Login Pagge</h1>
            </PageWrapper>
        )
    }
}

export default Login