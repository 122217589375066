import React from "react";

class MainSlider extends React.Component{
    render(){
        return(
            <section id="mainSlider" className="main-slider-wrapper">
                <div className="main-slider-container">
                    <div className="main-slider">
                        <div className="main-slider-item prev">
                            <div className="main-slider-item-image" style={{backgroundImage: "url('/images/main.jpg')"}}></div>
                            <div className="main-slider-item-text">SEA VIEW</div>
                        </div>
                        <div className="main-slider-item active">
                            <div className="main-slider-item-image" style={{backgroundImage: "url('/images/main.jpg')"}}></div>
                            <div className="main-slider-item-text">PEACE AND QUIET</div>
                        </div>
                        <div className="main-slider-item next">
                            <div className="main-slider-item-image" style={{backgroundImage: "url('/images/main.jpg')"}}></div>
                            <div className="main-slider-item-text">FREE PARKING</div>
                        </div>
                    </div>
                    <div className="main-slider-arrow main-slider-arrow-left"></div>
                    <div className="main-slider-arrow main-slider-arrow-right"></div>
                </div>
            </section>
        )
    }
}

export default MainSlider;