import React from "react";
import adminApi from "../api/admin-api";
import AdminContext from "../AdminContext";

class AdminGallery extends React.Component{
    static contextType = AdminContext;

    async componentDidMount(){
        let galData = await adminApi.galleryGetAllImages(this.context.authToken);
        console.log(galData);
    }

    render(){
        return(
            <div>Gallery Admin</div>

        )
    }
}

export default AdminGallery;